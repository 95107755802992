<template>
    <div>
        <itemCard
                :type="type"
                :data="computedData"
                :action="action"
                :actionText="actionText"
                :updated_at="updated_at"
        />
    </div>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
export default {
  mixins: [listItem],
  components: {
    itemCard: () => import("@/modules/base/components/applyPay/applyPayItemCard.vue"),
  },
  computed: {
    computedData() {
      return this.data;
    },
    count() {
      return this.data.count
    },
    actionText() {
      return this.data.status === 'pending' ? '請款中':'請款完成'
    },
      type() {
          return this.data.status === 'pending' ? 'secondary':'primary'
      },
  },
  created() {
    this.getStoreApplyPayIndex();
  },
  methods: {
    action() {
      this.$router.push({
        name: "apply-pay-detail",
        params: {
          storeId: this.data.store_id,
          id: this.data.apply_pay_id
        },
      });
    },
    async getStoreApplyPayIndex() {
      try {
        this.$store.dispatch('loading/active');
        const {
          data: storeApplyPayIndex,
        } = await this.$api.collection.applyPayApi.storeApplyPayIndex({
          store: this.data.store_id,
        });

        this.updated_at = await storeApplyPayIndex.find(
          (item) => item.applyPay_id == this.data.apply_pay_id
        ).updated_at;
      } catch (error) {
        console.error('Error occurred while fetching storeApplyPayIndex:', error);
      } finally {
        this.$store.dispatch('loading/close');
      }
    },
  },
};
</script>
